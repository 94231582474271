import React from "react";

export const ServiceCard = ({
  heading,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
}) => {
  return (
    <div data-aos="fade-up" className="bg-zinc-800 px-8 pb-6 group">
      <h1 className="text-[130px] font-bold -mt-16 text-zinc-900 group-hover:text-primary transition-all duration-700 font-head">
        {heading}
      </h1>
      <h4 className="text-white text-2xl font-bold -mt-5">{text1}</h4>
      <div className="flex flex-col gap-y-3 mt-6">
        <p className="text-white hover:text-primary transition-all duration-700">
          / {text2}
        </p>
        <p className="text-white hover:text-primary transition-all duration-700">
          / {text3}
        </p>
        <p className="text-white hover:text-primary transition-all duration-700">
          / {text4}
        </p>
        <p className="text-white hover:text-primary transition-all duration-700">
          / {text5}
        </p>
        <p className="text-white hover:text-primary transition-all duration-700">
          / {text6}
        </p>
      </div>
    </div>
  );
};
