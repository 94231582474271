import { BrowserRouter as Router } from "react-router-dom";
import Pages from "./pages";

//hooks
import ScrollToTheTop from "./hooks/ScrollToTheTop";

//Aos
import AOS from "aos";
import "aos/dist/aos.css";

// Redux
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Alert } from "./components/Layout/Alert";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
    AOS.refresh();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Alert />
          <ScrollToTheTop />
          <Pages />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
