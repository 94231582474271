import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { TbPhoneCall } from "react-icons/tb";
import { ImLocation } from "react-icons/im";
import { BsSpeedometer } from "react-icons/bs";
import {
  GiCarBattery,
  GiCarKey,
  GiCartwheel,
  GiCarWheel,
  GiSpanner,
} from "react-icons/gi";
import { SiGodotengine, SiMercedes, SiToyota, SiVolvo } from "react-icons/si";
import { FaCar, FaCheckCircle, FaOilCan } from "react-icons/fa";
import { RiCheckboxCircleLine } from "react-icons/ri";

import { PrivateRoute } from "../components/PrivateRoute";
import { ServiceCard } from "../components/card/ServiceCard";

const Pages = () => {
  return (
    // <Switch>
    <div className="relative">
      <div className="w-full  relative m-0 py-3">
        <img src="/img/mmabro.jpg" className="w-60 mx-auto " />
      </div>

      <div className="bg-zinc-700 h-screen relative pt-32 md:pt-20">
        <img
          className="w-[30%] top-0 left-0 h-[100%]  absolute object-cover "
          src="/img/homeslider1.jpg"
        />
        <img
          className="w-[70%] left-[30%] top-0  h-[100%]  absolute object-cover opacity-70 mix-blend-overlay"
          src="/img/gallery/workshop.jpg"
        />
        <div className="container mx-auto relative max-w-6xl px-8 ">
          <p data-aos="fade-right" className="text-white text-lg font-head">
            / ONLY THE BEST AUTO SERVICE
          </p>
          <h1
            data-aos="fade-right"
            className="text-white text-[40px] lg:text-[70px] font-bold max-w-xs lg:max-w-xl font-head leading-[50px] lg:leading-[80px] mt-5"
          >
            Only Advanced Innovative Solutions For Automobile
          </h1>
          <div
            data-aos="fade-up"
            className="ml-auto w-[330px] border-4 border-red-600 p-4 flex flex-col gap-y-6 mt-10 md:mt-0"
          >
            <a href="tel:+234 803 309 1697" className="flex gap-4">
              <TbPhoneCall className="text-white text-3xl" />
              <div className="text-white flex flex-col gap-y-1">
                <h1 className="font-bold text-xl font-head">Call Us 24/7</h1>
                <div className="text-lg font-extralight">+234 803 309 1697</div>
              </div>
            </a>
            <div className="flex gap-4">
              <ImLocation className="text-white text-6xl" />
              <div className="text-white flex flex-col gap-y-1">
                <h1 className="font-bold text-xl font-head">We are Here</h1>
                <a
                  target="_blanck"
                  href="https://maps.app.goo.gl/Gbrh9728t2Q5NHHw7"
                  className="text-lg font-extralight"
                >
                  No 7 Nkpokiti Road, Independence Layout, P.O.BOX 13516 Enugu.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-full bg-zinc-900 relative m-0  py-12">
        <div className="container max-w-2xl mx-auto grid grid-cols-3 lg:grid-cols-6 gap-y-4 lg:gap-x-16 text-white">
          <div className="flex flex-col items-center gap-y-3">
            <GiCarWheel className="text-zinc-500 text-2xl md:text-6xl" />
            <p>Tires</p>
          </div>
          <div className="flex flex-col items-center gap-y-3">
            <SiGodotengine className="text-zinc-500 text-2xl md:text-6xl" />
            <p>Engine</p>
          </div>
          <div className="flex flex-col items-center gap-y-3">
            <GiCarBattery className="text-zinc-500 text-2xl md:text-6xl" />
            <p>Battery</p>
          </div>
          <div className="flex flex-col items-center gap-y-3">
            <FaOilCan className="text-zinc-500 text-2xl md:text-6xl" />
            <p>Oil Filter</p>
          </div>
          <div className="flex flex-col items-center gap-y-3">
            <BsSpeedometer className="text-zinc-500 text-2xl md:text-6xl" />
            <p>BreakPad</p>
          </div>
          <div className="flex flex-col items-center gap-y-3">
            <GiCarKey className="text-zinc-500 text-2xl md:text-6xl" />
            <p>Diagnostics</p>
          </div>
        </div>
      </div> */}

      <div>
        <div className="container mx-auto grid  md:grid-cols-2 mt-20 gap-y-16 gap-x-8 max-w-5xl px-8">
          <div data-aos="fade-up" className="h-[400px] justify-self-center">
            <img className="h-[100%]" src="/img/gallery/mmabro.jpg" />
            <p className="text-gray-600 mt-2 text-center md:max-w-[250px] mx-auto text-xs">
              Noble Sir Emmanuel U. Okpala(KSJI / UNRS amb.) CEO
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h6 className="text-primary font-bold">/ WHO WE ARE</h6>
            <h2 className="text-black  text-3xl md:text-5xl font-medium max-w-md md:leading-[60px] font-head">
              Professional Car Services Since 1987
            </h2>
            <p className="text-gray-600 max-w-md md:mt-4 leading-8">
              Vehicles are becoming ever more complex and challenging to repair.
              Our service has the upper hand in overcoming these challenges by
              pairing technology and innovation and highly skilled technicians
              to meet the customer’s needs.
            </p>
          </div>
        </div>
      </div>

      <div className="relative h-[250px] md:h-[500px] w-full flex items-center justify-center mt-16 md:mt-24">
        <img
          className="absolute w-full h-full object-cover"
          src="/img/entrance.jpg"
        />
        <a
          target="_blanck"
          href="https://maps.app.goo.gl/Gbrh9728t2Q5NHHw7"
          className="bg-green-600 relative w-[160px] md:w-[200px]  text-white text-center rounded-lg py-3 shadow-lg text-xs md:text-sm cursor-pointer hover:bg-white hover:text-green-900 hover:border-2 hover:border-green-600 hover:font-bold transition-all duration-500"
        >
          FIND US ON THE MAP
        </a>
      </div>

      <div>
        <div className="container mx-auto grid md:grid-cols-2  px-8 gap-y-10 mt-16 md:mt-24">
          <div className="flex flex-col gap-y-4 justify-self-center">
            <h6 className="text-primary font-bold">/ WHY CHOOSE US</h6>
            <h2 className="text-black text-3xl md:text-5xl font-medium max-w-lg md:leading-[60px] font-head">
              General Computer Diagnostics
            </h2>
            <p className="text-gray-600 max-w-md md:mt-4 leading-8">
              We diagonize Engine, Transmission, Brakes, Suspension, Airbags,
              Cooling Systems, e.t.c.
            </p>
            <div className="flex flex-col gap-y-5 mt-4">
              <div className="flex gap-x-4">
                <RiCheckboxCircleLine className="text-3xl text-primary" />
                <p className="uppercase font-semibold font-head">
                  HAVE 12 HOURS EMERGENCY HOTLINE
                </p>
              </div>
              <div className="flex gap-x-4">
                <RiCheckboxCircleLine className="text-3xl text-primary" />
                <p className="uppercase font-semibold font-head">
                  MOBILE DIAGNOSTIC SERVICE AT HOME OR OFFICE
                </p>
              </div>
              {/* <div className="flex gap-x-4">
                <RiCheckboxCircleLine className="text-3xl text-primary" />
                <p className="uppercase font-semibold font-head">
                  MANAGE YOUR CAR ONLINE 24/7
                </p>
              </div> */}
            </div>
          </div>

          <div className="relative">
            <img
              data-aos="fade-up"
              className="absolute"
              src="/img/redcar.png"
            />
            <img
              data-aos="fade-up"
              className="absolute h-[250px] md:h-[450px] right-0 top-10"
              src="/img/carwheel.jpg"
            />
          </div>
        </div>
      </div>

      <div className="bg-zinc-900 mt-[400px] lg:mt-[300px] relative">
        <img className="absolute md:-right-20" src="/img/servicebg1.png" />
        <img className="absolute bottom-0" src="/img/servicebg2.png" />
        <div className="container mx-auto py-16 md:py-32 relative max-w-6xl px-8">
          <div className="flex flex-col gap-y-4 justify-center items-center">
            <h6 className="text-primary font-bold font-head">WHAT WE OFFER</h6>
            <h2 className=" text-3xl md:text-5xl font-medium max-w-3xl text-white text-center md:leading-[60px] font-head">
              Over 1000+ Car Repairs, Replacements and Programming
            </h2>
          </div>
          <div className="grid md:grid-cols-3 mt-10 gap-10 gap-y-6">
            <ServiceCard
              heading={"01"}
              text1={"We Make It Easy"}
              text2={"Airdbags Replacement"}
              text3={"Timing Belt Replacement"}
              text4={"Injector Cleaning and calibrations"}
              text5={"Brake Pads Replacement"}
              text6={"Car is not Starting Inspection"}
            />
            <img
              data-aos="fade-up"
              className="object-cover md:h-full hover:scale-105 transition-all duration-700"
              src="/img/gallery/inpection.jpg"
            />

            <ServiceCard
              heading={"02"}
              text1={"Programming"}
              text2={"EUCs coding and programming"}
              text3={"Key Programming"}
              text4={"EUC matching"}
              text5={"Calibratons"}
              text6={"Adaptation"}
            />
            <img
              data-aos="fade-up"
              className="object-cover md:h-full hover:scale-105 transition-all duration-700"
              src="/img/gallery/test.jpg"
            />
            <ServiceCard
              heading={"03"}
              text1={"Best Auto Services"}
              text2={"Engine Replacement and Repair"}
              text3={"Transmission Repair"}
              text4={"Cooling system Repair"}
              text5={"Brake Repair"}
              text6={"Transaxles and Suspension"}
            />

            <img
              data-aos="fade-up"
              className="object-cover md:h-full hover:scale-105 transition-all duration-700"
              src="/img/gallery/engineremoval.jpg"
            />
            <ServiceCard
              heading={"04"}
              text1={"Brake Disc Resurfacing"}
              text2={"Say goodbye to shaky braking."}
              text3={"Preserves your original disc / drum"}
              text4={"Saves brake pads"}
              text5={"Don't wait until it's too late"}
              text6={"Don't risk your safety"}
            />
            <img
              data-aos="fade-up"
              className="object-cover md:h-full hover:scale-105 transition-all duration-700"
              src="/img/gallery/brakewheel.jpeg"
            />
          </div>
        </div>
      </div>

      <div className="bg-primary relative">
        <div className="container mx-auto py-16 md:py-32 relative max-w-6xl px-8">
          <div className="flex flex-col gap-y-4">
            <h6 className="text-white font-bold font-head ">
              / EXPLORE FEATURES
            </h6>
            <h2 className="text-3xl md:text-5xl font-medium  md:max-w-xl text-white  md:leading-[60px] font-head">
              Life’s Too Short to Spend It at the Repair Shop
            </h2>
          </div>
          <hr className="mt-10"></hr>
          <div className="grid md:grid-cols-3 mt-10 gap-10 ">
            <div data-aos="fade-up" className="flex gap-x-4 p-4">
              <GiSpanner className="text-white text-9xl -mt-8" />
              <div>
                <h4 className="text-white text-3xl font-head">Get a Quote</h4>
                <p className="text-white mt-4 leading-7 font-light">
                  Tell us what your car needs or ask for a diagnostic. Receive a
                  free, fast, fair & transparent price quote.
                </p>
              </div>
            </div>
            <div data-aos="fade-up" className="flex gap-x-4  p-4">
              <GiCartwheel className="text-white text-9xl -mt-8" />
              <div>
                <h4 className="text-white text-3xl font-head">
                  Book Appoinment
                </h4>
                <p className="text-white mt-4 leading-7 font-light">
                  Provide your home or office location. Tell us when to meet you
                  there. It's as easy as 1-2-3.
                </p>
              </div>
            </div>
            <div data-aos="fade-up" className="flex gap-x-4  p-4">
              <FaCar className="text-white text-9xl -mt-8" />
              <div>
                <h4 className="text-white text-3xl font-head">
                  Get Your Car Fixed
                </h4>
                <p className="text-white mt-4 leading-7 font-light">
                  That’s it. No more waiting in repair shops - our mechanics
                  come to you. Enjoy convenient car repair.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="container mx-auto py-16 md:py-24 relative max-w-5xl px-4">
          <div className="flex flex-col gap-y-0">
            <h6 className=" font-bold font-head">/ Traning</h6>
            <h2 className="text-lg md:text-3xl font-medium  md:leading-[60px] font-head">
              We Train People To Become Maintenance And Repair Technicians.
            </h2>
          </div>
          <div data-aos="fade-up" className="">
            <img src="./img/training.jpg" />
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="container mx-auto pb-16 md:pb-24 relative max-w-5xl px-4">
          <div className="flex flex-col gap-y-4 justify-center items-center">
            <h2 className="text-3xl md:text-4xl font-medium  text-black text-center md:leading-[60px] font-head">
              We Service Most Makers and Models
            </h2>
          </div>
          <div
            data-aos="fade-up"
            className="grid grid-cols-4 md:grid-cols-5 justify-items-center mt-16 gap-y-8"
          >
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/audi.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/bmw.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/chevrolet.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/chrysler.png"
            />
            <img
              className="h-3 md:h-6 object-cover mt-5 hover:scale-105 transition-all duration-500 object-center"
              src="/img/logo/dodge.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/ford.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/honda.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/infiniti.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/hyundai.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/jaguar.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/jeep.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/kia.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/landrover.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/lexus.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/mercedes.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/mitsubishi.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/nissan.png"
            />
            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/volvo.png"
            />

            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/volkswagen.png"
            />

            <img
              className="h-10 md:h-20 hover:scale-105 transition-all duration-500"
              src="/img/logo/toyota.png"
            />
          </div>
        </div>
      </div>

      <div className="relative  h-[250px] md:h-[500px] w-full flex items-center justify-center">
        <img
          className="absolute w-full h-full object-cover"
          src="/img/map.png"
        />
        <a
          target="_blanck"
          href="https://maps.app.goo.gl/Gbrh9728t2Q5NHHw7"
          className="bg-green-600 relative w-[160px] md:w-[200px]  text-white text-center rounded-lg py-3 shadow-lg text-xs md:text-sm cursor-pointer hover:bg-white hover:text-green-900 hover:border-2 hover:border-green-600 hover:font-bold transition-all duration-500"
        >
          FIND US ON THE MAP
        </a>
      </div>

      <div className="relative ">
        <div className="container mx-auto py-10 relative max-w-5xl px-8">
          <img src="/img/mmabro.jpg" className="w-60 mx-auto " />

          <div className="grid gap-y-4 md:grid-cols-3 md:justify-items-center pt-10">
            <div className="flex flex-col gap-y-4">
              <div>
                <h4 className="text-primary text-lg font-head">Call Us 24/7</h4>
                <a
                  href="tel:+234 803 309 1697"
                  className="text-blue-500 underline"
                >
                  +234 803 309 1697
                </a>
              </div>
              <div>
                <h4 className="text-primary text-lg font-head ">Email</h4>
                <a
                  target="_blanck"
                  href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=mmabrointernationalmotor@gmail.com"
                  className=" cursor-pointer text-blue-500 underline"
                >
                  mmabrointernationalmotor@gmail.com
                </a>
              </div>
              <div>
                <h4 className="text-primary text-lg font-head">We are Here</h4>
                <a
                  target="_blanck"
                  href="https://maps.app.goo.gl/Gbrh9728t2Q5NHHw7"
                  className="text-black"
                >
                  No 7 Nkpokiti Road, Independence Layout, P.O.BOX 13516 Enugu.
                </a>
              </div>
            </div>

            <div>
              <h1 className="text-black mt-3 text-lg md:text-center">
                Open Hours
              </h1>
              <div className="mt-3 flex flex-col gap-y-2">
                <h3 className="text-black">
                  Mon - Fri:
                  <span className="text-gray-800 font-light pl-5">
                    8.00 am - 6.00 pm
                  </span>
                </h3>
                <h3 className="text-black">
                  Saturday:
                  <span className="text-gray-800 font-light pl-5">
                    9.00am - 5.00 pm
                  </span>
                </h3>
                {/* <h3 className="text-black">
                  Sunday:
                  <span className="text-gray-300 font-light pl-5">
                    9.00am - 6.00 pm
                  </span>
                </h3> */}
              </div>
            </div>

            <div>
              <h2 className="text-black text-2xl font-head">
                Need Help? We are Here to Help You!
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    // </Switch>
  );
};

export default Pages;
